import { ReactComponent as InfoIcon } from "../assets/info.svg";
import { ReactComponent as EmailIcon } from "../assets/mail.svg";
import useUIText from "../hooks/translation";


const NoResults = ({ txt }) => {
	const uiText = useUIText();
	return (
		<div className={txt === "" ? "no-results" : "no-results custom"}>
			{txt !== "" ? (
				<>
					<div className="no-results__icon">
						<InfoIcon />
					</div>
					<div className="no-results__text">
						<div className="no-results__text-title">
							{uiText?.noResults?.title}
						</div>
						<div className="no-results__text-subtitle">
							{uiText?.noResults?.subtitle}
						</div>
						<div className="no-results__email">
							<EmailIcon />
							<a href="mailto:support@innential.com">support@innential.com</a>
						</div>
					</div>
				</>
			) : (
				<div
					className="no-results__text"
					dangerouslySetInnerHTML={{ __html: txt }}
				/>
			)}
		</div>
	);
};

export default NoResults;
